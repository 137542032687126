import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgxSpinnerModule } from "ngx-spinner";
import { CheckTermsComponent } from "./components/check-terms/check-terms.component";
import { CopyrightsComponent } from "./components/copyrights/copyrights.component";
import { DocumentsViewerComponent } from "./components/documents-viewer/documents-viewer.component";
import { HeaderAccountComponent } from "./components/header-account/header-account.component";
import { HeaderItemComponent } from "./components/header-item/header-item.component";
import { HeaderLanguageComponent } from "./components/header-language/header-language.component";
import { HeaderNotificationsComponent } from "./components/header-notifications/header-notifications.component";
import { HeaderComponent } from "./components/header/header.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { MenuDividerComponent } from "./components/menu-divider/menu-divider.component";
import { MenuItemComponent } from "./components/menu-item/menu-item.component";
import { MessageComponent } from "./components/message/message.component";
import { ModuleHomeButtonComponent } from "./components/module-home-button/module-home-button.component";
import { ModuleHomePageComponent } from "./components/module-home-page/module-home-page.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PageMenuTemplateComponent } from "./components/page-menu-template/page-menu-template.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";
import { StatusCardComponent } from "./components/status-card/status-card.component";
import { ArianeStringComponent } from "./components/ui/ariane-string/ariane-string.component";
import { BigButtonComponent } from "./components/ui/big-button/big-button.component";
import { CardBodyInfosComponent } from "./components/ui/card/card-body-infos/card-body-infos.component";
import { CardBodyComponent } from "./components/ui/card/card-body/card-body.component";
import { CardFooterButtonComponent } from "./components/ui/card/card-footer-button/card-footer-button.component";
import { CardFooterComponent } from "./components/ui/card/card-footer/card-footer.component";
import { CardHeaderComponent } from "./components/ui/card/card-header/card-header.component";
import { CardComponent } from "./components/ui/card/card/card.component";
import { CarouselComponent } from "./components/ui/carousel/carousel.component";
import { EasyAlertComponent } from "./components/ui/easy-alert/easy-alert.component";
import { EasyButtonComponent } from "./components/ui/easy-button/easy-button.component";
import { EasyLoaderComponent } from "./components/ui/easy-loader/easy-loader.component";
import { EasyModalComponent } from "./components/ui/easy-modal/easy-modal.component";
import { EasyNotificationToastComponent } from "./components/ui/easy-notification-toast/easy-notification-toast.component";
import { EasyPaginatorComponent } from "./components/ui/easy-paginator/easy-paginator.component";
import { EasyProgressBarComponent } from "./components/ui/easy-progress-bar/easy-progress-bar.component";
import { EasyTableComponent } from "./components/ui/easy-table/easy-table.component";
import { EasyFormCheckboxComponent } from "./components/ui/forms/easy-form-checkbox/easy-form-checkbox.component";
import { EasyFormErrorsComponent } from "./components/ui/forms/easy-form-errors/easy-form-errors.component";
import { EasyFormInputComponent } from "./components/ui/forms/easy-form-input/easy-form-input.component";
import { EasyFormSelectComponent } from "./components/ui/forms/easy-form-select/easy-form-select.component";
import { EasySelectRecursiveOptionComponent } from "./components/ui/forms/easy-select-recursive-option/easy-select-recursive-option.component";
import { FormGroupComponent } from "./components/ui/forms/form-group/form-group.component";
import { FormSectionComponent } from "./components/ui/forms/form-section/form-section.component";
import { LedIndicatorComponent } from "./components/ui/led-indicator/led-indicator.component";
import { NotificationToastContainerComponent } from "./components/ui/notification-toast-container/notification-toast-container.component";
import { PageHeaderActionsComponent } from "./components/ui/page-header/page-header-actions/page-header-actions.component";
import { PageHeaderBackButtonComponent } from "./components/ui/page-header/page-header-back-button/page-header-back-button.component";
import { PageHeaderStatusComponent } from "./components/ui/page-header/page-header-status/page-header-status.component";
import { PageHeaderComponent } from "./components/ui/page-header/page-header/page-header.component";
import { StatsCardComponent } from "./components/ui/stats-card/stats-card.component";
import { LocalDatePipe } from "./pipes/localDate.pipe";
import { ObjectToKeysPipe } from "./pipes/object-to-keys.pipe";
import { SortPipe } from "./pipes/sort.pipe";

@NgModule({
  declarations: [
    MenuItemComponent,
    StatusCardComponent,
    EasyButtonComponent,
    EasyModalComponent,
    EasyAlertComponent,
    EasyFormInputComponent,
    EasyFormErrorsComponent,
    EasyFormSelectComponent,
    EasyLoaderComponent,
    EasyPaginatorComponent,
    EasySelectRecursiveOptionComponent,
    EasyTableComponent,
    ObjectToKeysPipe,
    SortPipe,
    LocalDatePipe,
    StatusBadgeComponent,
    HeaderComponent,
    HeaderItemComponent,
    HeaderAccountComponent,
    PageMenuTemplateComponent,
    MenuDividerComponent,
    ModuleHomePageComponent,
    ModuleHomeButtonComponent,
    HeaderLanguageComponent,
    BigButtonComponent,
    FormSectionComponent,
    FormGroupComponent,
    ArianeStringComponent,
    NotFoundComponent,
    LogoutComponent,
    SettingsComponent,
    CheckTermsComponent,
    MessageComponent,
    DocumentsViewerComponent,
    CopyrightsComponent,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardBodyInfosComponent,
    CardFooterButtonComponent,
    LedIndicatorComponent,
    PageHeaderComponent,
    PageHeaderBackButtonComponent,
    PageHeaderStatusComponent,
    PageHeaderActionsComponent,
    EasyFormCheckboxComponent,
    EasyProgressBarComponent,
    HeaderNotificationsComponent,
    EasyNotificationToastComponent,
    NotificationToastContainerComponent,
    CarouselComponent,
    StatsCardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    MenuItemComponent,
    StatusCardComponent,
    TranslateModule,
    EasyButtonComponent,
    EasyModalComponent,
    EasyAlertComponent,
    EasyFormInputComponent,
    EasyFormErrorsComponent,
    EasyFormSelectComponent,
    EasyLoaderComponent,
    EasyPaginatorComponent,
    EasySelectRecursiveOptionComponent,
    EasyTableComponent,
    ObjectToKeysPipe,
    SortPipe,
    LocalDatePipe,
    StatusBadgeComponent,
    ReactiveFormsModule,
    MatExpansionModule,
    RouterModule,
    HeaderComponent,
    MenuDividerComponent,
    PageMenuTemplateComponent,
    ModuleHomePageComponent,
    ModuleHomeButtonComponent,
    BigButtonComponent,
    FormSectionComponent,
    FormGroupComponent,
    ArianeStringComponent,
    NotFoundComponent,
    LogoutComponent,
    SettingsComponent,
    CheckTermsComponent,
    MessageComponent,
    DocumentsViewerComponent,
    CopyrightsComponent,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardBodyInfosComponent,
    CardFooterButtonComponent,
    LedIndicatorComponent,
    PageHeaderComponent,
    EasyFormCheckboxComponent,
    EasyProgressBarComponent,
    NotificationToastContainerComponent,
    CarouselComponent,
    StatsCardComponent,
  ],
})
export class SharedModule {}
